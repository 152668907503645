export const envs = {
  baseUrl: process.env.NEXT_PUBLIC_URL!,
  robotsIndex: process.env.NEXT_ROBOTS_INDEX === 'true',
  googleAnalyticsMeasurementId: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
  googleVerificationId: process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION_ID,
  isProduction: process.env.NODE_ENV === 'production',
  discordUrl: 'https://discord.gg/x66Arw4rRv',
  twitterUrl: 'https://twitter.com/intent/user?screen_name=tailframes_',
  githubTailframesComponentsUrl: 'https://github.com/Tailframes/react-components',
  figmaFileUrl: 'https://www.figma.com/community/file/1368254181288101112/tailframes-open-source-design-system-react-tailwind',
  figmaUrl: 'https://www.figma.com/design/RS7mo2486tQ6RUzhOm50zE/Tailframes-Open-Source-Design-System---React-%26-Tailwind-(Community)',
  iconsWaitingListFormUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSfNiq3lffdzlOsyzZ-raLV6oCJE5IuNNoTOc2BWEDEEnZzqHA/viewform?usp=sf_link',
};
