import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export interface AppStore {
  isSideNavOpened: boolean;
  setIsSideNavOpened: (value: boolean) => void;
  sideNavCollapsedSections: string[];
  setSideNavCollapsedSections: (value: string[]) => void;
}

export const useAppStore = create<AppStore, [['zustand/persist', Partial<AppStore>]]>(
  persist(
    set => ({
      isSideNavOpened: false,
      setIsSideNavOpened: isSideNavOpened => {
        set({ isSideNavOpened });
      },
      sideNavCollapsedSections: [],
      setSideNavCollapsedSections: sideNavCollapsedSections => {
        set({ sideNavCollapsedSections });
      },
    }),
    {
      name: 'tailframes-app',
      storage: createJSONStorage(() => localStorage),
      partialize: state => ({ sideNavCollapsedSections: state.sideNavCollapsedSections }),
    }
  )
);
