import type { SVGProps } from 'react';

export default function SectionIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      stroke='#94A3B8'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...props}
    >
      <path d='M10.2208 15.1152H3.37013C2.61343 15.1152 2 14.5126 2 13.7691V4.34614C2 3.60269 2.61343 3 3.37013 3H14.3311C15.0879 3 15.7013 3.60269 15.7013 4.34614V11.0768M2 5.69227H15.7013M15.7576 13.3418C16.0959 13.5462 16.0751 14.0438 15.7267 14.0826L13.9684 14.2785L13.1797 15.8348C13.0234 16.1432 12.5404 15.9922 12.4606 15.6099L11.6006 11.4934C11.5331 11.1704 11.8287 10.9671 12.1145 11.1399L15.7576 13.3418Z' />
    </svg>
  );
}

SectionIcon.displayName = 'SectionIcon';
