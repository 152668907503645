'use client';

import { useAppStore } from '@/app/lib/stores/app.store';
import MenuIcon from '@/app/ui/assets/icons/menu-icon';
import { Button } from '@/app/ui/components/atoms/button';

interface Props {
  className?: string;
  iconClassName?: string;
}

export default function MobileMenuButton({ className, iconClassName }: Props) {
  const setIsSideNavOpened = useAppStore(state => state.setIsSideNavOpened);

  return (
    <Button
      variant='text'
      size='xsmall'
      iconOnly
      onClick={() => {
        setIsSideNavOpened(true);
      }}
      aria-label='Menu'
      className={className}
    >
      <MenuIcon className={iconClassName} />
    </Button>
  );
}
