import { envs } from '@/app/lib/config/envs';
import type { ButtonProps } from 'tailframes-components/components/button';

interface Section extends Omit<ButtonProps, 'href' | 'children'> {
  name: string;
  href: string;
}

export const ComponentsSection: Section = { name: 'Components', href: '/components' };
export const IconsSection: Section = { name: 'Icons', href: '/icons' };
export const ChangelogSection: Section = { name: 'Changelog', href: '/changelog' };
export const LicenseSection: Section = { name: 'License', href: '/license' };
export const PrivacyPolicySection: Section = { name: 'Privacy Policy', href: '/privacy-policy' };
export const GitHubSection: Section = { name: 'GitHub', href: envs.githubTailframesComponentsUrl };
export const TwitterSection: Section = { name: 'Twitter', href: envs.twitterUrl };
export const DiscordSection: Section = { name: 'Discord', href: envs.discordUrl };
export const FigmaSection: Section = { name: 'Figma', href: envs.figmaFileUrl };
