'use client';

import { useEffect, useState } from 'react';

export const useScreenDetector = () => {
  const isServer = typeof window === 'undefined';
  const [width, setWidth] = useState(isServer ? 0 : window?.innerWidth);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [isServer]);

  const isMobile = width <= 768;
  const isTablet = width <= 1024;
  const isDesktop = width > 1024;

  return isServer ? {} : { isMobile, isTablet, isDesktop };
};
