import { clsxMerge } from '@/app/lib/utils/styles';
import type { ButtonProps as TailframesButtonProps } from 'tailframes-components/components/button';
import { Button as TailframesButton } from 'tailframes-components/components/button';

interface ButtonProps extends Omit<TailframesButtonProps, 'size'> {
  size?: TailframesButtonProps['size'] | 'xsmall';
}

const xsmall = 'h-6 min-w-[24px] gap-1.5 rounded-md text-xs';

export const Button = ({ size, className, ...props }: ButtonProps) => {
  return <TailframesButton className={clsxMerge({ [xsmall]: size === 'xsmall' }, className)} size={size === 'xsmall' ? 'small' : size} {...props} />;
};

Button.displayName = 'Button';
