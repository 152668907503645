import type { SVGProps } from 'react';

export default function FrameIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' stroke='#94A3B8' strokeWidth='1.5' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M4.07639 11.2567L2.12747 9.30775C1.95751 9.13773 1.95751 8.86225 2.12747 8.69223L4.07639 6.74329C4.24635 6.57334 4.52189 6.57334 4.69184 6.74329L6.64077 8.69223C6.81072 8.86225 6.81072 9.13773 6.64077 9.30775L4.69184 11.2567C4.52189 11.4267 4.24635 11.4267 4.07639 11.2567Z' />
      <path d='M8.69228 15.8725L6.74338 13.9235C6.57344 13.7536 6.57344 13.478 6.74338 13.3081L8.69228 11.3591C8.86229 11.1892 9.13784 11.1892 9.30778 11.3591L11.2567 13.3081C11.4266 13.478 11.4266 13.7536 11.2567 13.9235L9.30778 15.8725C9.13784 16.0425 8.86229 16.0425 8.69228 15.8725Z' />
      <path d='M8.69228 6.64086L6.74338 4.69189C6.57344 4.52194 6.57344 4.24638 6.74338 4.07643L8.69228 2.12746C8.86229 1.95751 9.13784 1.95751 9.30778 2.12746L11.2567 4.07643C11.4266 4.24638 11.4266 4.52194 11.2567 4.69189L9.30778 6.64086C9.13784 6.81081 8.86229 6.81081 8.69228 6.64086Z' />
      <path d='M13.3081 11.2567L11.3592 9.30775C11.1893 9.13773 11.1893 8.86225 11.3592 8.69223L13.3081 6.74329C13.4781 6.57334 13.7537 6.57334 13.9236 6.74329L15.8725 8.69223C16.0425 8.86225 16.0425 9.13773 15.8725 9.30775L13.9236 11.2567C13.7537 11.4267 13.4781 11.4267 13.3081 11.2567Z' />
    </svg>
  );
}

FrameIcon.displayName = 'FrameIcon';
