import { clsxMerge } from '@/app/lib/utils/styles';
import type { HTMLAttributes } from 'react';

export default function Copyright({ className, ...props }: HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p className={clsxMerge('text-xs text-slate-500', className)} {...props}>
      &#169; Tailframes {new Date().getFullYear()}
    </p>
  );
}

Copyright.displayName = 'Copyright';
