import { PrivacyPolicySection, LicenseSection, ChangelogSection, IconsSection } from '@/app/lib/config/routing';
import FrameIcon from '@/app/ui/assets/icons/frame-icon';
import SectionIcon from '@/app/ui/assets/icons/section-icon';
import type { ReactNode, SVGProps } from 'react';

export enum NavSectionType {
  GettingStarted = 'getting-started',
  Marketing = 'marketing',
  Components = 'components',
  Icons = 'icons',
}

export interface NavItem {
  name: string;
  href: string;
  direct?: boolean;
}

export interface NavSection {
  type: NavSectionType;
  label: string;
  href: string;
  icon?: (props: SVGProps<SVGSVGElement>) => ReactNode;
  items: NavItem[];
}

export const navSections: NavSection[] = [
  {
    type: NavSectionType.GettingStarted,
    label: 'Getting Started',
    href: `/${NavSectionType.GettingStarted}`,
    items: [
      {
        name: 'Introduction',
        href: '/introduction',
      },
      {
        name: 'Installation Guide',
        href: '/installation-guide',
      },
      {
        name: IconsSection.name,
        href: IconsSection.href,
        direct: true,
      },
      {
        name: LicenseSection.name,
        href: LicenseSection.href,
        direct: true,
      },
      {
        name: PrivacyPolicySection.name,
        href: PrivacyPolicySection.href,
        direct: true,
      },
      {
        name: ChangelogSection.name,
        href: ChangelogSection.href,
        direct: true,
      },
    ],
  },
  {
    type: NavSectionType.Components,
    label: 'Components',
    icon: FrameIcon,
    href: `/${NavSectionType.Components}`,
    items: [
      {
        name: 'Accordion',
        href: '/accordion',
      },
      {
        name: 'Alert',
        href: '/alert',
      },
      {
        name: 'Avatar',
        href: '/avatar',
      },
      {
        name: 'Badge',
        href: '/badge',
      },
      {
        name: 'Breadcrumbs',
        href: '/breadcrumbs',
      },
      {
        name: 'Button',
        href: '/button',
      },
      {
        name: 'Checkbox',
        href: '/checkbox',
      },
      {
        name: 'Divider',
        href: '/divider',
      },
      {
        name: 'Input',
        href: '/input',
      },
      {
        name: 'Radio',
        href: '/radio',
      },
      {
        name: 'Rate',
        href: '/rate',
      },
      {
        name: 'Segmented Control',
        href: '/segmented-control',
      },
      {
        name: 'Select',
        href: '/select',
      },
      {
        name: 'Spinner',
        href: '/spinner',
      },
      {
        name: 'Switch',
        href: '/switch',
      },
      {
        name: 'Tabs',
        href: '/tabs',
      },
      {
        name: 'Tag',
        href: '/tag',
      },
      {
        name: 'Textarea',
        href: '/textarea',
      },
      {
        name: 'Toast',
        href: '/toast',
      },
      {
        name: 'Tooltip',
        href: '/tooltip',
      },
    ].sort((a, b) => a.name.localeCompare(b.name)),
  },
  {
    type: NavSectionType.Marketing,
    label: 'Marketing Sections',
    icon: SectionIcon,
    href: `/${NavSectionType.Marketing}`,
    items: [
      {
        name: 'Header',
        href: '/header',
      },
      {
        name: 'Hero Section',
        href: '/hero-section',
      },
      {
        name: 'Feature Section',
        href: '/feature-section',
      },
      {
        name: 'Pricing Section',
        href: '/pricing-section',
      },
      {
        name: 'Blog Section',
        href: '/blog-section',
      },
      {
        name: 'Footer',
        href: '/footer',
      },
    ],
  },
];
