'use client';

import { useAppStore } from '@/app/lib/stores/app.store';
import CloseIcon from '@/app/ui/assets/icons/close-icon';
import LogoText from '@/app/ui/assets/logo-text';
import Copyright from '@/app/ui/components/atoms/copyright';
import { useScreenDetector } from '@/app/ui/hooks/use-screen-detector';
import NavLinks from '@/app/ui/navigation/nav-links';
import Link from 'next/link';

export default function SideNav() {
  const { isSideNavOpened, setIsSideNavOpened } = useAppStore();
  const { isDesktop } = useScreenDetector();

  return (
    <aside
      aria-label='Side Navigation'
      className={`fixed z-50 h-screen min-h-screen w-full overflow-auto transition-all duration-300 ease-in-out xl:static xl:z-0 xl:block xl:w-auto xl:min-w-[240px] xl:translate-x-0 xl:transition-none 2xl:min-w-[300px] min-[1881px]:ml-[calc((100vw-320px-1560px)/2)] ${
        isSideNavOpened ? 'inset-0' : '-translate-x-full overflow-hidden'
      }`}
    >
      <div className='flex min-h-full flex-col border-r border-gray-200 bg-white p-6 md:py-4 lg:pl-6 lg:pr-4'>
        <div className='mb-9 flex items-center justify-between'>
          <Link href={!isDesktop ? '' : '/'} aria-label='Home' title='Home'>
            <LogoText />
          </Link>
          <CloseIcon
            className='size-5 stroke-blue-700 xl:hidden'
            onClick={() => {
              setIsSideNavOpened(false);
            }}
          />
        </div>
        <div className='mb-4 flex grow flex-row justify-between lg:mb-0 lg:h-[calc(100vh-142px)] lg:overflow-auto lg:pr-2 xl:flex-col'>
          <NavLinks />
          <div className='hidden h-auto w-full grow xl:block'></div>
        </div>
        <div className='bottom-0 mt-4 w-full bg-white'>
          <Copyright />
        </div>
      </div>
    </aside>
  );
}
